// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import RichText from '~plugins/prismic/components/RichText';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import BodySection from '~plugins/material-ui/components/BodySection';
import WhitePaperCard from '~components/WhitePaperCard';
import type { PrismicWhitePaperArchive } from '~schema';
import useArchiveFilters from '~hooks/useArchiveFilters';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'textWrapper'
  | 'title'
  | 'subtitle'
  | 'itemGridContainer'
  | 'itemGridItem'
  | 'whitePaper';

export type Props = {
  // $FlowFixMe --> Reason: cannot get `node?.data` because property `data` is missing in  `PrismicDocument`
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicWhitePaperArchive>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {},
  textWrapper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  title: {
    color: theme.palette.common.black,
  },
  subtitle: {
    '* + &': {
      marginTop: theme.spacing(3),
    },
  },
  itemGridContainer: {
    '* + &': {
      marginTop: theme.spacing(7),
    },
  },
  itemGridItem: {},
  whitePaper: {
    width: '100%',
    height: 'auto',
    '&:hover': {
      boxShadow:
        '0px 3px 4px -1px rgba(0,0,0,0.1), 0px 6px 8px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
});

const WhitePaperArchivePage = ({ classes, data, node }: Props) => {
  const sortedNewsEntries = sortBy(data?.allPrismicWhitePaper?.nodes, [
    entry => entry?.data?.publication_date || entry?.first_publication_date,
  ]).reverse();

  const [filter, filteredEntries] = useArchiveFilters(sortedNewsEntries);

  return (
    <div className={classes.root}>
      <BodySection className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <RichText {...node?.data?.title} className={classes.title} />
          <RichText
            {...node?.data?.name?.subtitle}
            className={classes.subtitle}
          />
        </div>
        {filter}
        <Grid
          container
          spacing={4}
          alignItems="stretch"
          className={classes.itemGridContainer}
        >
          {map(filteredEntries, (whitePaper, index: number) => {
            return (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                className={classes.itemGridItem}
              >
                <WhitePaperCard
                  title={whitePaper?.data?.title}
                  description={whitePaper?.data?.description}
                  image={whitePaper?.data?.background_image}
                  link={whitePaper?.data?.document_link}
                  label={whitePaper?.data?.title}
                  whitePaper={whitePaper}
                  className={classes.whitePaper}
                />
              </Grid>
            );
          })}
        </Grid>
      </BodySection>
    </div>
  );
};

export default compose(
  withNodePage<PrismicWhitePaperArchive, *>({
    getNode: data => data?.prismicWhitePaperArchive,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'WhitePaperArchivePage' }),
)(WhitePaperArchivePage);

export const query = graphql`
  query WhitePaperArchiveQuery($prismicId: ID, $prismicLocaleId: String) {
    prismicWhitePaperArchive(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
      }
    }
    allPrismicWhitePaper(filter: { lang: { eq: $prismicLocaleId } }) {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        data {
          title {
            text
            html
          }
          description {
            text
            html
          }
          document_link {
            id
            link_type
            url
          }
          tags {
            tag {
              id
              uid
              document {
                ... on PrismicEntryTag {
                  id
                  uid
                  data {
                    name {
                      text
                      html
                    }
                  }
                }
              }
            }
          }
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          zoho_listkey
          zoho_lead_source
          zoho_industry
        }
      }
    }
  }
`;
